import type { AssessmentV1 } from '@/types/assessment.model'
import { useAuthStore } from '@/stores/auth'
import { axiosInstance, API_ENDPOINTS } from '@/services/axios.service'
import { type AxiosResponse } from 'axios'
import type { Standard } from '@/types/standards.model'

export class AssessmentService {
  static getAssessmentTypeLabel(assessmentType: string): string {
    switch (assessmentType) {
      case 'ibx':
        return 'Item Bank'
      case 'legacy_itembank':
        return 'Legacy Item Bank'
      case 'manual_hybrid':
        return 'Manual/Hybrid'
      case 'flex':
        return 'Flexible'
      default:
        return 'Unknown'
    }
  }
  static getPluraLabel(length: number, label: string) {
    if (length === 1) {
      return `${length} ${label}`
    }
    return `${length} ${label}s`
  }
  static getAssessmentPreviewUrl(assessment: AssessmentV1) {
    const authStore = useAuthStore()
    const bearerToken = import.meta.env.VITE_RENDER_SERVICE_TOKEN
    if (import.meta.env.VITE_ENV === 'local') {
      return (
        '/assessment-render/index.html' +
        '?token=' +
        bearerToken +
        '&client_id=' +
        authStore.user?.client_id
      )
    } else {
      return (
        '/render/assessment/' +
        assessment.nib_assessment_id +
        '?token=' +
        bearerToken +
        '&client_id=' +
        authStore.user?.client_id
      )
    }
  }

  async getStandards(assessmentGuid: string): Promise<AxiosResponse<Standard[]>> {
    try {
      return axiosInstance.get(`${API_ENDPOINTS.ASSESSMENTS}/${assessmentGuid}/standards`)
    } catch (error) {
      throw new Error('Failed to get standards for this assessment')
    }
  }
}

export const assessmentService = new AssessmentService()
