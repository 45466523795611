import { API_ENDPOINTS, axiosInstance } from '@/services/axios.service'
import { type AxiosResponse } from 'axios'
class FolderService {
  async fetchFolders(): Promise<AxiosResponse> {
    try {
      return axiosInstance.get(API_ENDPOINTS.FOLDERS)
    } catch (error) {
      throw new Error('Failed to get folders')
    }
  }
  async createFolder(folderName: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(API_ENDPOINTS.FOLDERS, { name: folderName })
    } catch (error) {
      throw new Error('Failed to create folder')
    }
  }
  async renameFolder(folderName: string, folderId: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`${API_ENDPOINTS.FOLDERS}/${folderId}`, { name: folderName })
    } catch (error) {
      throw new Error('Failed to rename folder')
    }
  }
  async deleteFolder(folderId: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.delete(`${API_ENDPOINTS.FOLDERS}/${folderId}`)
    } catch (error) {
      throw new Error('Failed to delete folder')
    }
  }
  async moveAssessmentToFolder(
    assessmentGuids: string[],
    folderId: string
  ): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`${API_ENDPOINTS.FOLDERS}/${folderId}/assessments`, {
        assessment_guids: assessmentGuids
      })
    } catch (error) {
      throw new Error('Failed to move assessment to folder')
    }
  }

  async removeAssessmentsFromFolder(
    folderId: string,
    assessmentGuids: string[]
  ): Promise<AxiosResponse> {
    try {
      return axiosInstance.delete(`${API_ENDPOINTS.FOLDERS}/${folderId}/assessments`, {
        data: {
          assessment_guids: assessmentGuids
        }
      })
    } catch (error) {
      throw new Error('Failed to move assessment to folder')
    }
  }
}
export const folderService = new FolderService()
