import { axiosInstance } from '@/services/axios.service'
import { type AxiosResponse } from 'axios'
import type {
  SearchResults,
  SearchCriteriaV1,
  FieldType,
  AssessmentType,
  Grade,
  Subject,
  Tag
} from '@/types'
import type { AuthUser, UserAssesesmentPermissions } from '@/types/user.model'
import type { Question, preSelectedFilters } from '@/types/filters.model'
import type { AssessmentCriteria } from '@/types/search-criteria.model'
import type { FavoriteAssessment } from '@/types/assessment.model'
import type { Standard } from '@/types/standards.model'

export interface ApiServiceInterface {
  fetchGrades(): Promise<AxiosResponse<Grade[]>>
  fetchAssessmentCreators(): Promise<AxiosResponse<string[]>>
  fetchAssessmentTypes(): Promise<AxiosResponse<string[]>>
  fetchTags(): Promise<AxiosResponse<string[]>>
  fetchSubjects(): Promise<AxiosResponse<string[]>>
  fetchSchools(): Promise<AxiosResponse<string[]>>
  fetchSearchResults(payload: SearchCriteriaV1): Promise<AxiosResponse<SearchResults>>
  fetchUserSession(payload: AuthUser): Promise<AxiosResponse<AuthUser>>
}

export class ApiService {
  // Fetch Assessment Types
  async fetchAssessmentTypes(): Promise<AxiosResponse<AssessmentType[]>> {
    return axiosInstance.get('/assessment-types')
  }

  // Fetch Tags
  async fetchTags(): Promise<AxiosResponse<Tag[]>> {
    return axiosInstance.get<Tag[]>('/tags')
  }

  // Fetch Standards
  async fetchStandards(assessmentGuid: string): Promise<AxiosResponse<Standard[]>> {
    return axiosInstance.get(`/assessments/${assessmentGuid}/standards`)
  }

  // Fetch Subjects
  async fetchSubjects(): Promise<AxiosResponse<Subject[]>> {
    const response = await axiosInstance.get<Subject[]>('/assessments/subjects')
    response.data.sort((subject1, subject2) => subject1.name.localeCompare(subject2.name))
    return response
  }

  // Fetch Grades
  async fetchGrades(): Promise<AxiosResponse<Grade[]>> {
    const response = await axiosInstance.get<Grade[]>('/assessments/grades')
    response.data.sort((grade1, grade2) => grade1.sort_order - grade2.sort_order)
    return response
  }

  // Fetch Search Results
  async fetchSearchResults(payload: SearchCriteriaV1): Promise<AxiosResponse<SearchResults>> {
    return axiosInstance.post('/assessments/search', payload)
  }

  async fetchAssessmentById(payload: AssessmentCriteria): Promise<AxiosResponse<SearchResults>> {
    return axiosInstance.post('/assessments/search', payload)
  }

  // Fetch Field Types
  async fetchFieldTypes(): Promise<AxiosResponse<FieldType[]>> {
    return axiosInstance.get('/field-types')
  }

  // Fetch User Session
  async fetchUser(): Promise<AxiosResponse<AuthUser>> {
    return axiosInstance.get('/user-session')
  }
  // Fetch Pre-Selected Filters
  async fetchPreSelectedFilters(): Promise<AxiosResponse<preSelectedFilters> | null> {
    try {
      return await axiosInstance.get<preSelectedFilters>('/filter-data')
    } catch (error) {
      return null
    }
  }
  // Post Pre-Selected Filters
  async postPreSelectedFilters(
    payload: preSelectedFilters
  ): Promise<AxiosResponse<preSelectedFilters>> {
    return axiosInstance.post(`/filter-data`, payload)
  }

  async fetchQuestions(): Promise<Question[]> {
    const questions = [
      { id: '1', name: '1-10 Questions' },
      { id: '2', name: '11-25 Questions' },
      { id: '3', name: '26+ Questions' }
    ]
    return Promise.resolve(questions)
  }

  async updateFavoriteAssessment(payload: FavoriteAssessment): Promise<AxiosResponse<string[]>> {
    return axiosInstance.post(`/favorites`, payload)
  }

  async getFavoriteAssessments(): Promise<AxiosResponse<string[]>> {
    return axiosInstance.get(`/favorites`)
  }

  async getUserAssessmentPermissions(
    assessmentGuid: string
  ): Promise<AxiosResponse<UserAssesesmentPermissions>> {
    return axiosInstance.get(`/users/assessments/${assessmentGuid}/permissions`)
  }
}

// Export an instance if you want to use a singleton pattern
export const apiService = new ApiService()
