import { useAuthStore } from '@/stores/auth'

export class UserService {
  private authStore = useAuthStore()

  getInstanceName(): string {
    if (!this.authStore.user) {
      throw new Error('User is not logged in')
    }

    const url = this.authStore.user.instance.url
    const instanceNameRegex = /https:\/\/([^.]+)/
    const match = url.match(instanceNameRegex)

    return match ? match[1] : ''
  }

  getInstancePlusDomain(): string {
    if (!this.authStore.user) {
      throw new Error('User is not logged in')
    }

    const url = this.authStore.user.instance.url
    const fullInstanceRegex = /https:\/\/([^.]+)\.[^/]+\/([^/]+)/
    const match = url.match(fullInstanceRegex)

    const instanceName = this.getInstanceName()
    return match ? `${instanceName}.${match[2]}` : ''
  }

  getVisitorId(): string {
    if (!this.authStore.user) {
      throw new Error('User is not logged in')
    }

    const userId = this.authStore.user?.user?.id
    if (!userId) {
      throw new Error('User ID is not available')
    }
    return `user-${this.getInstancePlusDomain()}-${userId}`
  }

  getAccountId(): string {
    if (!this.authStore.user || !this.authStore.user.client) {
      throw new Error('Error getting accountId for pendo')
    }

    return `${this.authStore.user.client.name}-${this.getInstanceName()}`
  }

  getAccountName(): string {
    if (!this.authStore.user || !this.authStore.user.client) {
      throw new Error('Error getting account name for pendo')
    }

    return this.authStore.user.client.name
  }

  getCrmAccountNumber(): string {
    if (!this.authStore.user || !this.authStore.user.client) {
      throw new Error('Error getting crm account number for pendo')
    }

    return !this.authStore.user.client.crm_account_number
      ? 'f' // If it doesn't exist we set this value to 'f' per Renaissance pendo standards
      : this.authStore.user.client.crm_account_number
  }
}
