import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { POSITION, useToast, TYPE } from 'vue-toastification'
import type { ToastOptions } from 'vue-toastification/dist/types/types'

export class ToastService {
  private featureFlagStore = useFeatureFlagsStore()

  private showToastNotification() {
    // Directly access the flag property from the store
    const flags = this.featureFlagStore.flags
    return flags?.[FEATURE_FLAGS.TOAST_NOTIFICATION]
  }

  private getToastType(type: string): TYPE {
    switch (type.toLowerCase()) {
      case 'success':
        return TYPE.SUCCESS
      case 'error':
        return TYPE.ERROR
      case 'warning':
        return TYPE.WARNING
      case 'info':
        return TYPE.INFO
      default:
        return TYPE.DEFAULT
    }
  }

  private configureToast(type: string, timeout: number): { toast: any; options: ToastOptions } {
    const options: ToastOptions = {
      position: POSITION.TOP_RIGHT,
      type: this.getToastType(type),
      timeout: timeout,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false
    }
    const toast = useToast()
    return { toast, options }
  }

  triggerToast(message: string, type: string = 'default', timeout: number = 3000): boolean {
    const { toast, options } = this.configureToast(type, timeout)
    if (this.showToastNotification()) {
      try {
        toast(message, options)
        return true
      } catch (error) {
        console.error('Error displaying toast:', error)
        return false
      }
    } else {
      return false
    }
  }
}
