import { useAuthStore } from '@/stores/auth'

export default class InstanceService {
  public static getInstanceURL(): string {
    const authStore = useAuthStore()
    return authStore.user?.instance?.url || ''
  }

  public static navigateToDnaRoot() {
    this.redirectTo(this.getInstanceURL())
  }

  public static navigateToOldAMExperience() {
    this.redirectTo(`${this.getInstanceURL()}?action=list&page=Dna_AssessmentListController`)
  }

  public static navigateToNewItemBank(assessmentId: string) {
    this.navigateToUrl(`${this.getIbxUrl()}/#/assessment/${assessmentId}`)
  }

  public static navigateToAssessmentShare(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?action=index&assessment_id=${assessmentId}&page=Assessment_PermissionController`
    )
  }
  public static navigateToTestOnline(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&action=addRoster&page=Assessment_OnlineTestingController`
    )
  }
  public static navigateToSheetPrinting(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&action=index&page=LightningGrader_SheetPrintingController`
    )
  }
  public static navigateToAssessmentDashboard(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&action=index&page=Assessment_OnlineTestingController`
    )
  }
  public static navigateToOtherTestingOptions(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&page=Assessments_Overview_Controller#/administer`
    )
  }
  public static navigateToLegacyManualManageAssesment(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&page=Assessment_DetailsController&overview_redirect=1`
    )
  }

  public static navigateToFlexibleManageAssesment(assessmentId: string) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?assessment_id=${assessmentId}&page=Assessments_Hybrid_IndexController#/`
    )
  }

  public static navigateToCreateItemBankAssessment() {
    this.navigateToUrl(`${this.getIbxUrl()}/#/create-assessment`)
  }

  public static navigateToCreateFlexibleAssessment() {
    this.navigateToUrl(`${this.getInstanceURL()}?Assessments_Hybrid_IndexController#/create`)
  }
  public static navigateToCreateOnTheFlyAssessment(numberOfQuestions: number) {
    this.navigateToUrl(
      `${this.getInstanceURL()}?page=LightningGrader_CameraGraderController&number_of_questions=${numberOfQuestions}`
    )
  }
  public static navigateSkillsAssessment() {
    this.navigateToUrl(
      `${this.getInstanceURL()}?wizard=1&page=SkillAssessment_InformationController`
    )
  }
  public static getIbxUrl() {
    const url = this.getInstanceURL()
    return url.replace(/\/\/[^.]+/, '//ibx').replace('/live', '')
  }

  private static navigateToUrl(url: string) {
    window.open(url, '_blank')
  }

  private static redirectTo(URL: string) {
    window.location.href = URL
  }
}
