import { initialize } from 'launchdarkly-js-client-sdk'
import type { LDClient, LDContext } from 'launchdarkly-js-client-sdk'
import { useAuthStore } from '@/stores/auth'

export class FeatureFlagService {
  private ldClient: LDClient
  private authStore = useAuthStore()

  constructor() {
    this.ldClient = this.initializeLaunchDarkly()
  }

  private initializeLaunchDarkly() {
    const context: LDContext = {
      kind: 'multi',
      organization: {
        key: this.authStore.user?.client_id, // use the client_id as the organization key
        name: this.authStore.user?.client?.name
      },
      user: {
        key: this.authStore.user?.account_id, // Use the account_id as the user key
        name: this.authStore.user?.user?.name
      }
    }

    return initialize(import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID, context)
  }

  onReady(callback: () => void) {
    this.ldClient.on('ready', callback)
  }

  onChange(callback: () => void) {
    this.ldClient.on('change', callback)
  }

  onError(callback: () => void) {
    this.ldClient.on('error', callback)
  }

  getFlags() {
    return this.ldClient.allFlags()
  }

  // New destroy method for cleanup
  destroy() {
    if (this.ldClient) {
      this.ldClient.close() // Close the LaunchDarkly client connection
    }
  }
}
