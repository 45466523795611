export default class AxiosError extends Error {
  public statusCode: number
  public errorMsg: string
  public stackTrace?: string
  public body: any
  public statusText: string
  public httpMethod: string
  public endpoint: string

  constructor(
    statusCode: number,
    errorMsg: string,
    stackTrace: string | undefined,
    body: any,
    statusText: string,
    httpMethod: string,
    endpoint: string
  ) {
    super(`${statusCode} ${statusText} accessing route ${endpoint}`)
    this.name = this.constructor.name

    this.statusCode = statusCode
    this.errorMsg = errorMsg
    this.stackTrace = stackTrace
    this.body = body
    this.statusText = statusText
    this.httpMethod = httpMethod
    this.endpoint = endpoint

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  toString(): string {
    return `
    HttpError: ${this.errorMsg}
    Status Code: ${this.statusCode} (${this.statusText})
    HTTP Method: ${this.httpMethod}
    Endpoint: ${this.endpoint}
    Request Body: ${JSON.stringify(this.body, null, 2)}
    Stack Trace: ${this.stackTrace || 'N/A'}
      `
  }
}
