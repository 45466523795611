import { datadogRum } from '@datadog/browser-rum'
import type { RumInitConfiguration } from '@datadog/browser-rum'
import type { AuthUser } from '@/types/user.model'

export class DatadogService {
  public static initialized = false

  public static startDatadog() {
    if (!DatadogService.initialized) {
      const config: RumInitConfiguration = {
        applicationId: import.meta.env.VITE_DD_APP_ID,
        clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'dna-assessment-management-portal',
        env: import.meta.env.VITE_DD_APM_ENV,
        sessionSampleRate: +import.meta.env.VITE_DD_SESSION_SAMPLE_RATE || 100,
        sessionReplaySampleRate: +import.meta.env.VITE_DD_SESSION_REPLAY_SAMPLE_RATE || 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        version: import.meta.env.VITE_ENV + '-' + import.meta.env.VITE_APP_VERSION
      }
      datadogRum.init(config)
      DatadogService.initialized = true
    }
  }

  public static attachUserToSession(user: AuthUser) {
    let dataDogUserDetails: any = {
      id: user.account_id,
      district_id: user.client_id,
      district_user_id: user.district_user_id
    }

    if (user.impersonate) {
      dataDogUserDetails = {
        ...dataDogUserDetails,
        impersonate: {
          id: user.impersonate.account_id,
          district_id: user.impersonate.client_id,
          district_user_id: user.impersonate.district_user_id
        }
      }
    }

    datadogRum.setUser(dataDogUserDetails)
  }

  public static clearUserFromSession() {
    datadogRum.clearUser()
  }

  public static addError(message: string) {
    datadogRum.addError(message)
  }

  public static addErrorWithStack(err: any, ctx: object) {
    datadogRum.addError(err, ctx)
  }

  public static logUserAction(action: string): void {
    datadogRum.addAction(action)
  }
}
